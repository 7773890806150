import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { storageKey } from 'src/consts/storageKey'

import useLocalStorage from '../hooks/useLocalStorage'
import { isSameDay } from 'date-fns'
import {
  Product,
  ProductAvailableCalendar,
  SalesFormat,
  SalesType,
  SubCategory,
  Temperature,
  WeightUnit,
} from 'src/generated/graphql'
import produce from 'immer'
import { DEFAULT_CATEGORY_ID } from 'src/consts/itemCategory'
import { ItemListType } from 'src/consts/ItemListType'

export type CartItem = {
  id: number //Item id
  selectedDeliveryDate: string
  selectedProductQuantity: number
  imageUrl: string
  productName: string
  shopName: string
  unitQuantity: number
  unit: string
  price: number
  productId: string
  inventoryQuantity: number
  maxQuantity?: number
  productAvailableCalendar?: ProductAvailableCalendar
  published: boolean
  categoryId: string
  subCategories: SubCategory[]
  itemListTypes: ItemListType
  salesType?: SalesType
  shippingData?: string
  avaiilableEndDate?: string
  temperature?: Temperature
  invoiceFlg: boolean
  taxRate: number
  indefiniteOrMarketvalue: SalesFormat
  indefiniteValuePerUnit: number
  marketvalueMin: number
  marketvalueMax: number
  weightQuantity: string[]
  weightUnit: WeightUnit
}

interface State {
  currentCartItems: CartItem[]
}

type Action =
  | {
      type: 'add'
      payload: { item: CartItem }
    }
  | {
      type: 'remove'
      payload: { itemId: number }
    }
  | {
      type: 'updateQuantity'
      payload: {
        updateInfo: {
          itemId: number
          selectedProductQuantity: number
        }
      }
    }
  | {
      type: 'updateDeliveryDate'
      payload: {
        updateInfo: {
          itemId: number
          productId: string
          selectedDeliveryDate: string
        }
      }
    }
  | {
      type: 'updateShippingDate'
      payload: {
        updateInfo: {
          itemId: number
          productId: string
          shippingDate: string
        }
      }
    }
  | {
      type: 'updateAvailableCalendar'
      payload: {
        updateInfo: {
          itemId: number
          productId: string
          productAvailableCalendar: ProductAvailableCalendar
        }
      }
    }
  | {
      type: 'updateMaxQuantity'
      payload: {
        updateInfo: { itemId: number; maxQuantity: number }[]
      }
    }
  | {
      type: 'clear'
    }
  | {
      type: 'refresh'
      payload: { productsWithLatestInfo: Product[] }
    }

function cartReducer(state: State, action: Action) {
  const { currentCartItems } = state

  switch (action.type) {
    case 'add': {
      const inputProduct = action.payload.item
      const sameProductIdAndDeliveryDateItem = currentCartItems.find(
        (product: CartItem) =>
          product.productId === inputProduct.productId &&
          isSameDay(new Date(product.selectedDeliveryDate), new Date(inputProduct.selectedDeliveryDate))
      )
      if (sameProductIdAndDeliveryDateItem) {
        return {
          currentCartItems: currentCartItems.map((product: CartItem) => {
            if (product.id === sameProductIdAndDeliveryDateItem.id) {
              return {
                ...product,
                selectedProductQuantity: product.selectedProductQuantity + inputProduct.selectedProductQuantity,
              }
            }
            return product
          }),
        }
      } else {
        return {
          currentCartItems: [
            ...currentCartItems,
            inputProduct, //Same product with different 到着希望日 or different product altogether
          ],
        }
      }
    }
    case 'remove': {
      //payload is item id of the item in the cart (not product id)
      return {
        currentCartItems: currentCartItems.filter((product: CartItem) => product.id !== action.payload.itemId),
      }
    }
    case 'updateQuantity': {
      const inputProductInfo = action.payload.updateInfo
      return {
        currentCartItems: produce(currentCartItems, (draftCartItems) => {
          draftCartItems.map((product: CartItem) => {
            if (product.id === inputProductInfo.itemId) {
              product.selectedProductQuantity = inputProductInfo.selectedProductQuantity
            }
            return product
          })
        }),
      }
    }

    case 'updateDeliveryDate': {
      const inputProductInfo = action.payload.updateInfo
      const sameProductIdAndDeliveryDateItem = currentCartItems.find(
        (product: CartItem) =>
          product.id !== inputProductInfo.itemId &&
          product.productId === inputProductInfo.productId &&
          isSameDay(new Date(product.selectedDeliveryDate), new Date(inputProductInfo.selectedDeliveryDate))
      )

      const filteredCartItems = currentCartItems.filter(
        (product: CartItem) => !sameProductIdAndDeliveryDateItem || product.id !== sameProductIdAndDeliveryDateItem.id //Filter duplicate
      )

      return {
        currentCartItems: produce(filteredCartItems, (draftCartItems) => {
          draftCartItems.map((product: CartItem) => {
            if (product.id === inputProductInfo.itemId) {
              if (inputProductInfo.selectedDeliveryDate) {
                product.selectedDeliveryDate = inputProductInfo.selectedDeliveryDate
              }

              if (sameProductIdAndDeliveryDateItem) {
                //Merge the selectedProductQuantity of the duplicate item which is already filtered
                product.selectedProductQuantity += sameProductIdAndDeliveryDateItem.selectedProductQuantity
              }
            }
            return product
          })
        }),
      }
    }
    case 'updateShippingDate': {
      const inputProductInfo = action.payload.updateInfo
      return {
        currentCartItems: produce(currentCartItems, (draftCartItems) => {
          draftCartItems.map((product: CartItem) => {
            if (product.id === inputProductInfo.itemId) {
              product.shippingData = inputProductInfo.shippingDate
            }
            return product
          })
        }),
      }
    }

    case 'updateAvailableCalendar': {
      const inputProductInfo = action.payload.updateInfo
      return {
        currentCartItems: produce(currentCartItems, (draftCartItems) => {
          draftCartItems.map((product: CartItem) => {
            if (product.id === inputProductInfo.itemId) {
              product.productAvailableCalendar = inputProductInfo.productAvailableCalendar
            }
            return product
          })
        }),
      }
    }

    case 'updateMaxQuantity': {
      const inputProductInfo = action.payload.updateInfo
      return {
        currentCartItems: produce(currentCartItems, (draftCartItems) => {
          draftCartItems.map((product: CartItem) => {
            inputProductInfo.map((info) => {
              if (product.id === info.itemId) {
                product.maxQuantity = info.maxQuantity
              }
            })
            return product
          })
        }),
      }
    }

    case 'clear': {
      return {
        currentCartItems: [],
      }
    }
    case 'refresh': {
      //payload  Product[]
      return {
        currentCartItems: produce(currentCartItems, (draftCartItems) => {
          action.payload.productsWithLatestInfo.forEach((product: Product) => {
            draftCartItems
              .filter((item: CartItem) => item.productId === product.id)
              .forEach((item: CartItem) => {
                item.inventoryQuantity = product.inventory?.quantity as number
                item.productAvailableCalendar = product.availableCalendar as ProductAvailableCalendar
                item.imageUrl = product.images[0].url
                item.productName = product.name
                item.unitQuantity = product.unitQuantity
                item.unit = product.unit
                item.price = product.price
                item.published = product.published
                item.categoryId = product.category?.id || DEFAULT_CATEGORY_ID
                item.subCategories = product.subCategories || []
                item.salesType = product.salesType
                item.avaiilableEndDate = product.avaiilableEndDate ?? undefined
                item.temperature = product.temperature ?? undefined
                item.invoiceFlg = product.seller?.invoiceFlg ?? false
                item.taxRate = product.taxRate
              })
          })
        }),
      }
    }
  }
}

type Dispatch = (action: Action) => void
export const CartStateContext = createContext<State | undefined>(undefined)
export const CartDispatchContext = createContext<Dispatch | undefined>(undefined)

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [storedValue, setValue] = useLocalStorage(storageKey.LOCAL_CART_ITEMS, [])
  const [state, dispatch] = useReducer(cartReducer, {
    currentCartItems: storedValue,
  })

  useEffect(() => {
    // @ts-expect-error TS2345
    state && state.currentCartItems && setValue(state.currentCartItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]) //Dont include setValue as dependency as it would create an infinite loop

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>{children}</CartDispatchContext.Provider>
    </CartStateContext.Provider>
  )
}

export const useCartDispatch = () => {
  const context = useContext(CartDispatchContext)
  if (context === undefined) {
    throw new Error('useCartDispatch must be used within a CartProvider')
  }

  return context
}

export const useCartState = () => {
  const context = useContext(CartStateContext)
  if (context === undefined) {
    throw new Error('useCartState must be used within a CartProvider')
  }

  return context
}
