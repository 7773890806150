import { useAuth0 } from '@auth0/auth0-react'
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  GetProfileDocument,
  UserType,
  BuyerFieldsFragment,
  BuyerAllOrderHistoryQueryDocument,
} from 'src/generated/graphql'
import { useQuery } from 'urql'
import { useRouter } from 'next/router'
import { BUYER_ROUTE, SELLER_ROUTE, GUEST_ROUTE, Route, COMMON_ROUTE, SHOWSELLER_ROUTE } from 'src/consts/route'
import { storageKey } from 'src/consts/storageKey'
import { Spinner } from 'src/components/Spinner/Spinner'
import { setGtagUserProperties } from 'src/util/gtag'
import { isDefinedBuyer, isDefinedSeller } from 'src/guards/user'
import { identifyByHotjar } from 'src/util/hotjar'
import { BUYABLE_PREFECTURE } from 'src/consts/buyablePrefecture'
import { getPostalCode } from 'src/lib/postalCodeApi'
import { BUYABLE_CITY } from 'src/consts/buyableCity'
import { BUYABLE_BUYER_ID_WHITE_LIST } from 'src/consts/buyableBuyerIdWhiteList'

const CurrentUserContext = createContext<CurrentUserModel>(undefined as any)

export type CurrentUserModel = Omit<ReturnType<typeof useCurrentUserInternal>, 'fetching'>

export function useCurrentUser() {
  return useContext(CurrentUserContext)
}

function useCurrentUserInternal() {
  const [isBuyableUser, setIsBuyableUser] = useState(false)
  const { isAuthenticated, user } = useAuth0()

  const [res, executeGetProfile] = useQuery({
    query: GetProfileDocument,
    requestPolicy: 'network-only',
    pause: !isAuthenticated,
  })

  const role: UserType | undefined | null = useMemo(() => {
    if (!res.data) {
      return undefined
    }
    if (res.data.getProfile.__typename === 'UserNotFound') {
      return res.data.getProfile.type
    }
    if (res.data.getProfile.__typename === 'Buyer') {
      return UserType.Buyer
    }
    if (res.data.getProfile.__typename === 'Seller') {
      return UserType.Seller
    }
  }, [res])

  const showRole = typeof sessionStorage !== 'undefined' ? sessionStorage.getItem(storageKey.BUTTON_ACTIVE) : null

  const isOnboarded = useMemo(() => {
    if (res.data?.getProfile.__typename === 'UserNotFound') {
      return false
    }
    if (res.data?.getProfile.__typename === 'Buyer' || res.data?.getProfile.__typename === 'Seller') {
      return true
    }
    return false
  }, [res])

  const id = useMemo(() => {
    if (res.data?.getProfile.__typename === 'UserNotFound') {
      return undefined
    }
    if (res.data?.getProfile.__typename === 'Buyer' || res.data?.getProfile.__typename === 'Seller') {
      return res.data.getProfile.id
    }
  }, [res.data])

  const isBuyer = role === UserType.Buyer && isOnboarded

  const isSalesApproved = useMemo(() => {
    if (res.data?.getProfile.__typename === 'Seller') {
      return res.data.getProfile.salesApproved
    }
    return false
  }, [res])

  const profile = useMemo(() => {
    return res.data?.getProfile
  }, [res])

  const pfRate = useMemo(() => {
    if (!isDefinedSeller(profile)) return
    return profile.platFormRates?.pfRate
  }, [profile])

  const productContentClearWarnFlag = useRef(isDefinedSeller(profile) ? profile.productContentClearWarnFlag : true)

  const validateBuyableAddress = async (zipCode: string, prefectureCode: number) => {
    await getPostalCode(zipCode)
      .then(({ data }) => {
        const cityName = data[0].ja.address1
        const isBuyableCity = BUYABLE_CITY[prefectureCode]?.includes(cityName)
        if (isBuyableCity) {
          setIsBuyableUser(true)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (!isDefinedBuyer(profile)) {
      return
    }
    // 対象ユーザーのみエリア問わず有効とする
    if (BUYABLE_BUYER_ID_WHITE_LIST.includes(profile.id)) {
      setIsBuyableUser(true)
      return
    }
    const buyablePrefectureCodes = Object.keys(BUYABLE_PREFECTURE)
    const isBuyablePrefecture = buyablePrefectureCodes.includes(String(profile.prefectureCode))
    if (!isBuyablePrefecture) {
      return
    }
    validateBuyableAddress(profile.zipCode, profile.prefectureCode)
  }, [profile])

  const [buyerOrderHistoryRes] = useQuery({
    query: BuyerAllOrderHistoryQueryDocument,
    pause: !isAuthenticated || !isBuyer,
  })
  const productIds: string[] = []
  if (buyerOrderHistoryRes.data) {
    buyerOrderHistoryRes.data.allOrders.forEach((order) => {
      order.orderDetails.forEach((orderDetail) => {
        if (orderDetail.productId) {
          productIds.push(`"${orderDetail.productId}"`)
        }
      })
    })
  }
  // 購入した商品の一覧
  const purchaseProductIds = productIds.join(',')

  return {
    fetching: res.fetching,
    isOnboarded,
    isBuyableUser,
    role,
    showRole,
    isAuthenticated,
    emailVerified: user?.email_verified,
    id,
    executeGetProfile,
    isSalesApproved,
    profile,
    pfRate,
    productContentClearWarnFlag,
    purchaseProductIds,
  }
}

const matchPath = (routePathes: string[], path: string) => routePathes.some((it) => it === path)

export function CurrentUserProvider({ children }: { children: React.ReactNode }) {
  const value = useCurrentUserInternal()
  const router = useRouter()
  const { pathname } = router
  const { fetching, ...currentUser } = value
  const { role, isAuthenticated, isOnboarded, id, emailVerified, showRole, profile, purchaseProductIds } = currentUser
  const { loginWithRedirect } = useAuth0()

  //pathがauth-loadingで、redirectToが指定されている場合は、そのページにリダイレクトする
  if (pathname === Route.authLoading) {
    const redirectTo = router.query.redirectTo
    if (typeof redirectTo === 'string') {
      router.push(redirectTo)
      return <Spinner />
    }
  }

  if (matchPath(Object.values(Route) as string[], pathname)) {
    if (fetching) {
      return <Spinner />
    }

    if (isOnboarded) {
      if (role === UserType.Buyer) {
        if (
          (!matchPath(BUYER_ROUTE, pathname) && matchPath(SELLER_ROUTE, pathname)) ||
          pathname === Route.sellerOnboarding
        ) {
          router.push(Route.error)
          return null
        }

        if (!matchPath(BUYER_ROUTE, pathname)) {
          // @ts-expect-error TS2339
          setGtagUserProperties(id, UserType.Buyer, profile?.shopName)
          if (profile && profile.__typename === 'Buyer') {
            const buyerProfile = profile as BuyerFieldsFragment
            identifyByHotjar(buyerProfile.id, UserType.Buyer, buyerProfile.shopName, purchaseProductIds)
          }
          router.push(Route.productSearch)
          return null
        } else if (pathname === Route.productSearch) {
          // @ts-expect-error TS2339
          setGtagUserProperties(id, UserType.Buyer, profile?.shopName)
          if (profile && profile.__typename === 'Buyer') {
            const buyerProfile = profile as BuyerFieldsFragment
            identifyByHotjar(buyerProfile.id, UserType.Buyer, buyerProfile.shopName, purchaseProductIds)
          }
        }
      }

      if (role === UserType.Seller) {
        if (!(showRole === UserType.Buyer)) {
          if (
            (!matchPath(SELLER_ROUTE, pathname) && matchPath(BUYER_ROUTE, pathname)) ||
            pathname === Route.onboarding
          ) {
            router.push(Route.error)
            return null
          }
          if (!matchPath(SELLER_ROUTE, pathname)) {
            // @ts-expect-error TS2339
            setGtagUserProperties(id, UserType.Seller, profile?.shopName)
            router.push(Route.sellerHome)
            return null
          } else if (pathname === Route.sellerHome) {
            // @ts-expect-error TS2339
            setGtagUserProperties(id, UserType.Seller, profile?.shopName)
          }
        }
        if (showRole === UserType.Buyer) {
          if (
            (!matchPath(SHOWSELLER_ROUTE, pathname) &&
              (matchPath(BUYER_ROUTE, pathname) || matchPath(SELLER_ROUTE, pathname))) ||
            pathname === Route.sellerOnboarding
          ) {
            router.push(Route.error)
            return null
          }
        }
      }
    } else {
      // fix: 認証リンクに通してTOPページに遷移する　↓↓↓
      // https://www.notion.so/PlanB-TOP-7fca8ddb14914fcf8c036a4e9902a5d9
      if (pathname == Route.authLoading && typeof router.query?.success !== 'undefined') {
        if (isAuthenticated) {
          if (router.query.success === 'false' && router.query.message === 'Access expired.') {
            // 認証リンク期限切れ
            // backend連携、認証メール再送する
            router.push(Route.sendVerificationEmail)
            return null
          }
        } else {
          if (
            router.query.success === 'true' ||
            router.query.message == 'This URL can be used only once' ||
            router.query.message == 'This account is already verified.'
          ) {
            // 既に認証リンクを押した場合かつ
            // 未ログイン状態、ログイン画面へ
            loginWithRedirect({})
            return null
          } else if (router.query.message === 'Access expired.') {
            // 認証リンク期限切れ
            // ログイン画面へ
            sessionStorage.setItem(storageKey.AUTH0_VERIFICATION_EMAIL_EXPIRED, 'true')
            loginWithRedirect()
            return null
          } else {
            // 以外の場合はエラー画面へ
            router.push(Route.genericError)
            return null
          }
        }
      }
      // fix: 認証リンクに通してTOPページに遷移する　↑↑↑

      if (
        !emailVerified &&
        (role === UserType.Buyer || role === UserType.Seller) &&
        pathname !== Route.verifyEmail &&
        !matchPath(COMMON_ROUTE, pathname)
      ) {
        // fix: 認証リンクに通してTOPページに遷移する　↓↓↓
        // https://www.notion.so/PlanB-TOP-7fca8ddb14914fcf8c036a4e9902a5d9
        const isExpired = sessionStorage.getItem(storageKey.AUTH0_VERIFICATION_EMAIL_EXPIRED)
        if (isExpired === 'true') {
          router.push(Route.sendVerificationEmail)
          return null
        }
        // fix: 認証リンクに通してTOPページに遷移する　↑↑↑
        router.push(Route.verifyEmail)
        return null
      }

      if (
        emailVerified &&
        role === UserType.Buyer &&
        !matchPath(GUEST_ROUTE, pathname) &&
        pathname !== Route.onboarding &&
        pathname !== Route.verifyEmail
      ) {
        router.push(Route.verifyEmail)
        return null
      }
      if (
        emailVerified &&
        role === UserType.Seller &&
        !matchPath(GUEST_ROUTE, pathname) &&
        pathname !== Route.sellerOnboarding &&
        pathname !== Route.verifyEmail
      ) {
        router.push(Route.verifyEmail)
        return null
      }
    }

    if (
      !isAuthenticated &&
      !matchPath(GUEST_ROUTE, pathname) &&
      pathname !== Route.genericError &&
      typeof window !== 'undefined' &&
      pathname !== Route.authLoading &&
      // auth0のログインセッションはin-memoryに保持しており、LINEドメインからの戻りで一時的に取得できなくなる。
      // isAuthenticatedの判定がauth0の自動ログインの前に行われる為、以下のrouteで未ログイン扱いになってしまうことを防ぐ
      pathname !== Route.lineConnectAuth0AuthorizeCallback &&
      pathname !== Route.lineDisconnectCallback &&
      pathname !== Route.lineDisconnectConfirm &&
      pathname !== Route.lineLoginAuto
    ) {
      loginWithRedirect({
        type: 'login',
        redirectUri: `${window.location.origin}${Route.authLoading}?redirectTo=${window.location.pathname}${window.location.search}`,
      })
      return null
    }
  }

  return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>
}
